/*REACT*/
import React, { Component } from "react";
import PropsType from "prop-types";

class ZDetectBrowser extends Component {
  static propTypes = {
    onMounted: PropsType.func,
  };

  static defaultProps = {
    onMounted: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        window.addEventListener("resize", this.updateDimensions);
        this.DetectBrowser();
        this.updateDimensions();
      }
    );

    if (this.props.onMounted) {
      this.props.onMounted({
        isAndroid: () => {
          return this.GetState("isAndroid");
        },
        isGoogleBot: () => {
          return this.GetState("isGoogleBot");
        },
        isIE: () => {
          return this.GetState("isIE");
        },
        isIE11: () => {
          return this.GetState("isIE11");
        },
        isEdge: () => {
          return this.GetState("isEdge");
        },
        isFirefox: () => {
          return this.GetState("isFirefox");
        },
        isOpera: () => {
          return this.GetState("isOpera");
        },
        isChrome: () => {
          return this.GetState("isChrome");
        },
        isSafari: () => {
          return this.GetState("isSafari");
        },
        isBlink: () => {
          return this.GetState("isBlink");
        },
        GetBrowser: () => {
          return this.GetBrowser();
        },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;
    //document.documentElement.style.setProperty("--vh", `${vh}px`);    
    if (["iPad Simulator", "iPad"].includes(navigator.platform)) {
      document.documentElement.style.setProperty("--vh", `5.69px`);
    } else {
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    
    document.documentElement.style.setProperty("--vw", `${vw}px`);
    console.log({ width: window.innerWidth, height: window.innerHeight });
  };

  GetBrowser = () => {
    return this.state.browser;
  };

  GetState = (stateName) => {
    return this.state[stateName];
  };

  DetectBrowser = () => {
    let browser = "";
    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") !== -1;
    const isGoogleBot = navigator.userAgent.toLowerCase().indexOf("googlebot") !== -1;
    // eslint-disable-next-line
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    const isFirefox =
      typeof InstallTrigger !== "undefined" ||
      navigator.userAgent.toLowerCase().indexOf("firefox") !== -1 ||
      navigator.userAgent.toLowerCase().indexOf("fennec") !== -1 ||
      navigator.userAgent.toLowerCase().indexOf("fxios") !== -1;
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
    const isChrome = !isGoogleBot && !isOpera && !!window.chrome && (!!window.chrome.webstore || navigator.vendor.toLowerCase().indexOf("google inc.") !== -1);
    const isEdge = !isIE && !isChrome && navigator.userAgent.toLowerCase().indexOf("edg") !== -1;
    const isSafari = !isChrome && navigator.userAgent.toLowerCase().indexOf("safari") !== -1;
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isIE11) {
      browser = "ie11";
    } else if (isIE) {
      browser = "ie";
    } else if (isEdge) {
      browser = "edge";
    } else if (isFirefox) {
      browser = "firefox";
    } else if (isOpera) {
      browser = "opera";
    } else if (isChrome) {
      browser = "chrome";
    } else if (isSafari) {
      browser = "safari";
    } else if (isBlink) {
      browser = "blink";
    } else if (isGoogleBot) {
      browser = "googlebot";
    } else {
      browser = "safari";
    }

    browser = (isAndroid ? "android " : "") + browser;
    browser = (isGoogleBot ? "googlebot " : "") + browser;

    console.log("[-] Browser Detected: " + browser);
    this.setState({
      browser,
      isAndroid,
      isGoogleBot,
      // eslint-disable-next-line
      isIE11,
      isIE,
      isEdge,
      isFirefox,
      isOpera,
      isChrome,
      isSafari,
      isBlink,
    });

    return browser;
  };

  render() {
    return <div />;
  }
}

export default ZDetectBrowser;
