/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';

/*BASE*/
import LoadingSpin from 'react-loading-spin';

/*CSS*/
import './ZPopup.css';

class ZPopup extends Component {

  static propTypes = {
    onConfirm: PropsType.func,
    onCancel: PropsType.func,
    cssPrefix: PropsType.string,
    mode: PropsType.string,
    message: PropsType.string
  }

  static defaultProps = {
    onConfirm: () => {},
    onCancel: () => {},
    cssPrefix: '',
    mode: '',
    message: ''
  };

  constructor(props){
    super();
    this.state = {
      inuse: false
    };
  }

  componentDidMount(){
    this.setAllStates();
    if(this.props.onMounted){
      this.props.onMounted({
        toggle: () => this.toggle(),
        close: () => this.close(),
        open: () => this.open()
      });
    }
  }

  componentDidUpdate(prevProps, prevStats){
    if(prevProps !== this.props){
      this.setAllStates();
    }
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.clickOutside, false);
  }

  setAllStates = () => {
    this.setState((state, props) => ({
      ...props
    }));
  }

  onConfirm = () => {
    if(this.props.onConfirm){
      this.props.onConfirm();
    }else{
      this.toggle();
    }
  }

  onCancel = () => {
    if(this.props.onCancel){
      this.props.onCancel();
    }else{
      this.toggle();
    }
  }

  open = () => {
    if(this.state.inuse) return;
    this.toggle();
  }

  close = () => {
    if(!this.state.inuse) return;
    this.toggle();
  }

  toggle = () => {
    if(!this.state.inuse){
      document.addEventListener('mousedown', this.clickOutside, false);
    }else{
      document.removeEventListener('mousedown', this.clickOutside, false);
    }
    this.setState((state, props) => ({
      inuse: !state.inuse
    }));
  }

  clickOutside = (e) => {
    if (this.state.mode === "loading" || this.node.contains(e.target)) {
      return;
    }
    this.toggle();
  }

  renderAlert = () => {
    let {cssPrefix, message} = this.state;
    console.log(message);
    return (
      <div className={cssPrefix + " zpopup-form"}>
        <div className={cssPrefix + " zpopup-box"}>
          <div className={cssPrefix + " zpopup-message"}>
            {message}
          </div>
          <div className={cssPrefix + " zpopup-box-btnrow"}>  
            {this.renderButtons("OK")}
          </div>
        </div>
      </div>
    );
  }

  renderConfirm = () => {
    let {cssPrefix, message} = this.state;
    return (
      <div className={cssPrefix + " zpopup-form"}>
        <div className={cssPrefix + " zpopup-box"}>
          <div className={cssPrefix + " zpopup-message"}>
            {message}
          </div>
          <div className={cssPrefix + " zpopup-btnrow"}> 
            {this.renderButtons("Cancel")} 
            {this.renderButtons("OK")}
          </div>
        </div>
      </div>
    );
  }

  renderLoading = () => {
    let {cssPrefix} = this.state;
    return(
      <div className={cssPrefix + " zpopup-loading-form"}>
        <LoadingSpin
            width = '3px'
            size = '30px'
            primaryColor = 'white'
            secondaryColor = 'transparent'
        />
      </div>
    );
  }

  renderDefault = () => {
    let {mode} = this.state;
    switch(mode){
      case "alert" : return this.renderAlert();
      case "ask" : return this.renderConfirm();
      case "loading" : return this.renderLoading();
      default: return this.props.children;
    }
  }

  renderButtons = (type) => {
    let {cssPrefix, submitting} = this.state;
    let buttons = {
      "OK": 
      <button type="button" className={cssPrefix + " zpopup-btn submit"} disabled={submitting} onClick={this.onConfirm} key={0}>
        <i className="fas fa-paper-plane"/> OK
      </button>,
      "Cancel":
      <button type="button" className={cssPrefix + " zpopup-btn cancel"} disabled={submitting} onClick={this.onCancel} key={1}>
        <i className="fas fa-ban"/> Cancel
      </button>
    }

    return buttons[type];
  }

  render() {
    let {cssPrefix, inuse} = this.state;
    return (
    <div>
      {
        inuse?
        <div className={cssPrefix + " ZPopup"}>
          <div className={cssPrefix + " ZPopup-bg"}></div>
          <div className={cssPrefix + " ZPopup-content"} ref={node => { this.node = node; }}>
            {this.renderDefault()}
          </div>
        </div>
        : ""
      }
    </div>
    );
  }
}

export default ZPopup;
