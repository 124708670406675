/*REACT*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*BASE*/
import * as _base from "../../../_base";
import ZChat from "../../_Utilities/ZChat/ZChat";
import ZReduxForm from "../../_Utilities/ZReduxForm/ZReduxForm";
import ZRestrict from "../../_Utilities/ZRestrict/ZRestrict";
import ZPopup from "../../_Utilities/ZPopup/ZPopup";
import ZDetectBrowser from "../../_Utilities/ZDetectBrowser/ZDetectBrowser";

import { v1 } from "uuid";
import axios from "axios";
import _ from "lodash";
import ReactJson from "react-json-view";
import Translate from "./_Parts/Translate";

/*CSS*/
import "./Talk2Elain.css";
import "./Talk2ElainMS.css";
import "./mobi-widget.css";
import { DOMAIN } from "../../../_base/actions";
import { DEFAULT_INTL_CONFIG } from "react-intl/src/utils";
import PopUp from "./_Parts/Popup";

class Talk2Elain extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {

    super();
    this.state = {
      sessionID: v1(),
      lang: props.match.params.lang || "tc",
      intents: [],
      records: [],
      context: {
        DEBUG: false,
      },
      timeout: 6000,
      reminderEnabled: false,
      showReminder: true,
      showDisclaimer: false,
      showMenu: false,
      showSurvey: false,
      inputPlaceholder: "",
      available: true,
      popup: {
        type: "",
        message: "",
        onConfirm: () => { },
        onCancel: () => { },
      },
      LongPollAwaiting: false,
      base: {
        url: {
          LongPoll: "web/longPoll",
          awaiting: false,
        },
      },
      Connected: false,
      disclaimer: null
    };

    let deviceId = this.getCookie('deviceId');
    if (!deviceId) {
      deviceId = this.setCookie('deviceId', v1());
    }
    this.state.deviceID = deviceId;
  };

  getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  setCookie = (cname, cvalue) => {
    document.cookie = cname + "=" + cvalue + ";" + "expires=" + new Date(2099, 1, 1).toUTCString(); // set expiry to far future; note year 2038 bug
    return cvalue;
  };

  // onMountSurvey = (callbacks) => {
  //     this.MountSurvey = callbacks;
  // };

  onMountBrowser = (callbacks) => {
    this.MountBrowser = callbacks;
  };

  onMountChat = (callbacks) => {
    this.MountChat = callbacks;
  };

  onMountPopup = (callbacks) => {
    this.MountPopup = callbacks;
  };

  onMountBrowser = (callbacks) => {
    this.MountBrowser = callbacks;
  };

  getBrowser = () => {
    return this.MountBrowser && this.MountBrowser.GetBrowser() || "";
  };

  Alert = (msg) => {
    this.setState(
      {
        showSurvey: false,
      },
      () => {
        //this.MountSurvey.Alert(msg);
      }
    );
  };

  Loading = (callback) => {
    this.setState(
      (state, props) => ({
        popup: {
          ...state.popup,
          type: "loading",
          message: "",
          onConfirm: () => { },
          onCancel: () => { },
        },
      }),
      () => {
        this.MountPopup.open();
        if (callback) callback();
      }
    );
  };

  componentDidMount() {
    console.log(this.props.user.authority);
    this.setState((state, props) => ({
      ...props,
      authority: (props.user && props.user.authority && props.user.authority.Talk2Elain) || [],
    }));

    this.setAllStates(
      this.setChatbot(() => {
        console.log("[>] Connect to " + this.state.url);
        //this.getConfig();
        this.welcomeMessage();
      })
    );

    this.MountChat.Connecting(false);
    this.SetPlaceHolder();
    //this.LongPoll();
    window.addEventListener("beforeunload", this.endMessage);
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  BlockEntry = (unBlockTime) => {
    this.setState(
      {
        showSurvey: true,
        available: false,
      },
      () => {
        console.log(this.state.available);
        this.MountSurvey.Block(unBlockTime);

        if (this.UnblockTimeout) clearTimeout(this.UnblockTimeout);
        this.UnblockTimeout = setTimeout(() => {
          this.setState({
            available: true,
          });
        }, unBlockTime);
      }
    );
  };

  RestartChat = () => {
    this.setState({
      convID: v1(),
      records: [],
      messages: [],
      showMenu: false,
      showReminder: true,
      showDisclaimer: false,
    });
  };

  setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  setChatbot = (callback) => {
    console.log("[-] START CHATBOT");
    let { interval, channel } = _base.config.chatbot;

    this.setState(
      {
        convID: v1(),
        livechat: false,
        url: DOMAIN + "/Talk2VA",
        channel: channel,
        timeout: interval,
        sending: false,
      },
      callback
    );
  };

  getConfig = async () => {
    try {
      const { url } = this.state;
      const res = await axios.get(`${url}/GetConfig`);
      const config = res.data;
      this.setState({
        disclaimer: config.content.disclaimer
      })
    } catch (err) {
      console.error(err)
    }
  }

  welcomeMessage = async () => {
    console.log("[-] welcomeMessage");

    let { url, lang, sessionID, deviceID, channel } = this.state;

    url = url;

    let input = {
      type: "system",
      content: "CMD_WELCOME"
    };

    const wakeupPayload = {
      session_id: sessionID,
      device_id: deviceID,
      input: input,
      channel: channel,
      lang: lang.toUpperCase(),
      timestamp: new Date(),
    };
    let res = await axios.post(url, wakeupPayload);

    if (res.data.Success === true) {
      this.MountChat.Connecting(false);
      this.MountChat.SetStatus(sessionID, "read");

      res.data.message.forEach((message) => {
        message.msg.buttons = message.msg.quickReplies;
        delete message.msg.quickReplies;
      })

      this.MountChat.Append(res.data.message);
    }

    if (res.data.live_chat) {
      this.setState({ livechat: true });
      this.startLongPoll();
    }
  };

  endMessage = async () => {
    console.log("[-] endMessage");

    let { url, lang, sessionID, deviceID, livechat, channel } = this.state;

    url = url;

    let input = {
      type: "system",
      content: "CMD_ENDCHAT"
    };

    const wakeupPayload = {
      session_id: sessionID,
      device_id: deviceID,
      input: input,
      livechat: livechat,
      channel: channel,
      lang: lang.toUpperCase(),
      timestamp: new Date(),
    };
    let res = await axios.post(url, wakeupPayload);    
  };


  SetPlaceHolder = (mode) => {
    let { lang } = this.state;

    let placeholder = { tc: "", en: "" };
    let { inputField, connecting } = Translate;
    switch (mode) {
      case 0:
        placeholder = inputField.welcomePlaceholder;
        break;
      case 1:
        placeholder = inputField.buttonPlaceholderTC;
        break;
      default:
        placeholder = inputField.textPlaceholder;
        break;
    }

    this.setState({
      inputPlaceholder: placeholder[lang],
      connectReminder: connecting[lang],
    });
  };

  //#region Idle

  DocTitleBlink = (start) => {
    let title = "Live Chat Assistant";
    let newmsg = "New Message!!";
    if (start) {
      this.DocTitleBlinkHandler = setInterval(() => {
        document.title = document.title === title ? newmsg : title;
      }, 1000);
    } else {
      if (this.DocTitleBlinkHandler) {
        clearInterval(this.DocTitleBlinkHandler);
      }
      document.title = title;
    }
  };

  //#endregion Idle

  TriggerLiveAgent = () => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;
    this.setState(
      {
        showSurvey: true,
      },
      () => {
        this.MountSurvey.TriggerLiveAgent();
      }
    );
  };

  onSend = (input, id) => {
    console.log(input, id);

    let inputO = {
      type: "text",
      content: input.text,
    };
    //this.MountChat.Typing();
    this.sendToServer(inputO, id, true);
  };

  onQuickReply = (quickReply, _id) => {
    let input = {
      type: "button",
      content: quickReply.payload,
    };
    //this.MountChat.Typing();
    this.sendToServer(input, _id, true);
  };

  sendToServer = async (input, _id, record = false) => {
    let { url, sessionID, deviceID, channel, remarks, user, selectedEnv, livechat } = this.state;
    let startTime = new Date();

    let payload = {
      JWT: user.JWT,
      env: selectedEnv,
      data: {
        session_id: sessionID,
        device_id: deviceID,
        channel: channel,
        input: input,
        livechat: livechat,
        timestamp: new Date(),
        remarks: remarks,
      },
    };

    console.log("[>] Data Sent: " + startTime, payload.data);

    try {
      let res = await axios.post(url, payload.data);
      // this.MountChat.Typing(true);
      this.MountChat.SetStatus(_id, "received");

      if (res.data.Success === true) {
        let endTime = new Date();
        if (_id) this.MountChat.SetStatus(_id, "read");

        let processTime = (endTime - startTime) / 1000;
        console.log("[<] Data Received: " + endTime);
        console.log("[-] Process Time: " + processTime + "s");
        console.log("[-] MW Response: ", res.data);

        if (!livechat && res.data.live_chat) {
          this.setState({ livechat: true });
          this.startLongPoll();
        } else {
          if (livechat && res.data.live_chat === false) {
            this.setState({ livechat: false });
          }
        }

        res.data.message.forEach((message) => {
          message.msg.buttons = message.msg.quickReplies;
          delete message.msg.quickReplies;
        })
        this.MountChat.Append(res.data.message);

      } else {
        _base.func.handleError(this.props, res.data, (e) => {
          this.Alert(e);
        });
      }
    } catch (e) {
      this.MountChat.Typing(false);
      _base.func.handleError(this.props, e, (e) => {
        this.Alert(e);
      });
      if (_id) this.MountChat.SetStatus(_id, "pending");
    }
  };

  appendReturnMessage = (messages, _id) => {
    console.log(messages);
    this.MountChat.Append(messages.reverse());
    this.MountChat.SetStatus(_id, "read");

    let needForceRefresh = false;
    _.map(messages, (o, i) => {
      if (o.msg.table) needForceRefresh = true;
    });

    if (needForceRefresh) {
      setTimeout(() => {
        this.MountChat.ScrollToBottom();
      }, 500);
    }
  };

  Language = (input) => {
    let lang = "en";
    if (/\S?[\u4E00-\u9FFF\uF900-\uFAFF]\S?/i.test(input)) {
      lang = "tc";
    } else {
      lang = "en";
    }
    return lang;
  };

  onMsgPress = (messageId) => {
    console.log("onMsgPress", messageId);
  };

  onMsgLongPress = (messageId) => {
    console.log("onMsgLongPress", messageId);
  };

  renderForm = () => {
    let { form } = this.state;
    return (
      <ZReduxForm
        cssPrefix='t2einfo '
        form={form.id}
        title={form.title}
        schema={form.schema}
        onSubmit={form.onSubmit}
        onCancel={form.onCancel}
        defaultValue={form.editing}
        buttons={form.buttons}
        readOnly={form.readOnly}
      />
    );
  };

  renderOutput = () => {
    return (
      <div className='zbjsonviewer'>
        <ReactJson name={false} enableClipboard={true} src={this.state.form.editing.output} />
      </div>
    );
  };

  toPage = (page) => {
    this.setState({
      page: page,
    });
  };

  renderPageContent = (content, page) => {
    return <div className={"t2e-content" + this.getBrowser() + (this.state.page === page ? "" : " hidden")}>{content}</div>;
  };

  renderPageButton = (label, page, restricted = []) => {
    let { authority } = this.props.user;
    if (!authority || !authority.Talk2Elain) return;
    return (
      <ZRestrict auth={Object.keys(authority.Talk2Elain)} restricted={restricted} key={label}>
        <div className={"t2e-pages-btn" + this.getBrowser() + (this.state.page === page ? " current" : "")} onClick={() => this.toPage(page)}>
          {label}
        </div>
      </ZRestrict>
    );
  };

  ToggleLang = () => {
    this.setState(
      (state, prop) => ({
        lang: state.lang === "en" ? "tc" : "en",
      }),
      () => {
        this.SetPlaceHolder();
      }
    );
  };

  Leave = () => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;
    this.openSurvey(0);
  };

  onDisclaimerConfirm = () => {
    this.setState(
      {
        showDisclaimer: false,
      },
      () => {
        this.welcomeMessage();
        this.setIdleTimeout();
      }
    );
  };

  onLeaveRedirect = (timeout = 10000) => {
    let { lang } = this.state;
  };

  //#region Survey
  openSurvey = (step) => {
    this.setState(
      {
        showSurvey: true,
      },
      () => {
        this.MountSurvey.Start(step);
      }
    );
  };

  onSurveyClose = () => {
    this.setState({
      showSurvey: false,
    });
  };

  onSurveySetStep = (step) => {
    this.setState({
      surveyStep: step,
    });
  };

  //#endregion

  ParseButton = (msg) => {
    try {
      let textMsg = msg.text;
      if (!textMsg) return msg;

      let textMsgSplit = textMsg.split("<br>");
      const re = new RegExp("^[0-9][.]");

      let buttons = [];
      _.map(textMsgSplit, (o) => {
        let res = re.exec(o);
        if (!res) return;
        // [1] English -->[1]
        let value = res[0];

        // [1] English --> English
        let text = res["input"].replace(value, "");
        let paylaod = res[0].replace("[", "").replace("]", "");
        buttons.push({
          type: "text",
          title: res[0] + text,
          payload: paylaod,
        });
      });

      return buttons;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  sendFirstMessage = async () => {
    let { lang } = this.state;
    let toSend = "hi";
    if (lang == "tc") toSend = "1";
    if (lang == "en") toSend = "2";
    if (lang == "sc") toSend = "3";
    let input = {
      type: "text",
      content: toSend,
    };
    await this.sendToServer(input, null, true);
    this.setState({ Connected: true });
  };

  startLongPoll = async () => {
    let { livechat } = this.state;
    let status = "longpoll";

    while (status != "ended" && livechat) {
      status = await this.LongPoll();
      livechat = this.state.livechat;
    }

    let time = new Date();
    console.log("[>] Long Poll Ended: " + time, livechat, status);
  }

  LongPoll = async () => {
    let { interval } = _base.config.chatbot;
    let { url, sessionID, deviceID, channel, remarks, user, selectedEnv, livechat } = this.state;

    console.log("Long poll params", { url, sessionID, deviceID, channel, remarks, user, selectedEnv, livechat, interval })

    let startTime = new Date();

    let payload = {
      JWT: user.JWT,
      env: selectedEnv,
      data: {
        session_id: sessionID,
        device_id: deviceID,
        channel: channel,
        input: {
          type: "system",
          content: "CMD_POLLING"
        },
        timestamp: startTime,
        remarks: remarks,
      },
    };

    console.log("[>] Long Poll Sent: " + startTime, payload.data);

    try {
      let res = await axios.post(url, payload.data, {
        timeout: interval
      })

      if (res.data?.Success === true) {
        res.data.message.forEach((message) => {
          message.msg.text = message.msg.text
            .replace(/(^| |\r|\n|\t|>)([\w\.]*@[\w\.]*)( |$)/g, "$1<a href='mailto:$2' target='_blank'>$2</a>$3")
            .replace(/(^| |\r|\n|\t|>)(www\.[\w\.\\\/\-\=\?\&\:\@\*\#]*|eduhk\.[\w\.\\\/\-\=\?\&\:\@\*\#]*)( |$)/g, "$1<a href='//$2' target='_blank'>$2</a>$3")
            .replace(/(https?[\w\.\\\/\-\=\?\&\:\@\*\#]*)( |$)/g, "<a href='$1' target='_blank'>$1</a>$2");
        })
        console.log(res.data.message);
        this.MountChat.Append(res.data.message);
        if (res.data.live_chat === false) {
          this.setState({ livechat: false });
          return "ended";
        }
      } else {
        console.error(res.data);
      }
      return "polling";
    } catch (e) {
      if (e.response && e.response.status && e.response.status != 408) console.log(e);
      this.setState({ LongPollAwaiting: false });
      return "timeout";
    }
  }

  renderHeadLine() {
    let { lang } = this.state;
    let browser = this.getBrowser();
    return (
      <div className={"t2e-headline " + browser}>
        <div className={"t2e-headline-left " + browser}>
          <div className={"t2e-headline-icon logo " + browser}></div>
          <div className='t2e-headline-text'>{Translate.virturalAssistant[lang]}</div>
        </div>
      </div>
    );
  }

  renderBullet() {
    return (
      <div className='t2e-reminder-bullet'>
        <img src='Icon/bullet.png' alt='' draggable={false} />
      </div>
    );
  }

  renderSendBtn = () => {
    return <img src='Icon/send.png' alt='' />;
  };

  renderChat = () => {
    let browser = this.getBrowser();
    const { inputPlaceholder, connectReminder, lang, disclaimer } = this.state;
    return (
      <div className={"t2e-chat " + browser}>
        <ZChat
          cssPrefix={"mobi-widget " + browser}
          onMounted={this.onMountChat}
          onSend={this.onSend}
          onQuickReply={this.onQuickReply}
          onMsgLongPress={this.onMsgLongPress}
          onMsgPress={this.onMsgLongPress}
          user={{
            _id: 1,
          }}
          sendBtn={this.renderSendBtn}
          longAnswerLength={500}
          showStatus={true}
          animated={true}
          showQuickRepliesAsButtons={false}
          showLapseTime={true}
          browser={browser}
          inputPlaceHolder={inputPlaceholder}
          connectReminder={connectReminder}
        />
      </div>
    );
  };

  render() {
    let { popup } = this.state;
    let browser = this.getBrowser();
    return (
      <div className={"Talk2Elain " + browser}>
        <ZDetectBrowser onMounted={this.onMountBrowser} />
        <ZPopup onMounted={this.onMountPopup} cssPrefix={"mobi-widget"} mode={popup.type} message={popup.message} onConfirm={popup.onConfirm} onCancel={popup.onCancel} />
        <div className={"Talk2Elain-inner " + browser}>
          {this.renderHeadLine()}
          {this.renderChat()}
        </div>
      </div>
    );
  }
}

export default connect(_base.func.mapStateToProps, _base.actions)(Talk2Elain);
